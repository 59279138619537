import React, { useState } from 'react';
import Highcharts from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";

HighchartsHeatmap(Highcharts);
//Filtering function
function  filterByValue (array, string) {
  return array.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(string.toLowerCase())));
}

const colorCode={
                  "":"#bdbdbd",
                  "0":"#bdbdbd",
                  "1":"#00833e",
                  "2":"#a2d45e",
                  "3":"#ffe76a",
                  "4":"#ff8300",
                  "5":"#e84545"
                }

export default function ProjectSituationChart({props,quarter}) {
     // Get all quarters
  let quarters = [];
  for (let i = 0; i < props.length; i++){
      quarters[i] = props[i]["Quarter"];
  }
  let dataSource={};
    let localquarter,previousquarter,previous,selected;
    let prevAssesment, prevRelevance, prevAssumptions, prevPlan, prevImpact;
    let selAssesment, selRelevance, selAssumptions, selPlan, selImpact;

    if (quarters.includes(quarter)){
      localquarter=quarter;
      previousquarter=  quarters.sort().indexOf(quarter) !== 0 ? quarters[quarters.sort().indexOf(quarter)-1] : quarters[quarters.sort().indexOf(quarter)] ;
      previous  = filterByValue(props, previousquarter);
      selected  = filterByValue(props, localquarter);

      prevAssesment = previous[0]['Overall Project Assessment'];
      prevRelevance = previous[0]['Overall Project Relevance'];
      prevAssumptions = previous[0]['Overall Project Assumptions'];
      prevPlan = previous[0]['Overall Project Progress According to Plan'];      

      selAssesment = selected[0]['Overall Project Assessment'];
      selRelevance = selected[0]['Overall Project Relevance'];
      selAssumptions = selected[0]['Overall Project Assumptions'];
      selPlan = selected[0]['Overall Project Progress According to Plan'];      

    }else{
      localquarter=previousquarter='-';
      prevAssesment ='';
      prevRelevance ='';
      prevAssumptions = '';
      prevPlan = '';   


      selAssesment = '';
      selRelevance = '';
      selAssumptions = '';
      selPlan = '';

      
    }

      // risk previous:s
    



    let heatmapConstantOptions = {
      chart: {
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
        width: 250
      },
      title: {
        text: ""
      },
      yAxis: {
        categories: [
          "Project Assessment", 
          "Project Relevance", 
          "Project Assumption", 
          "Project Progression"],
        title: null,
        reversed: true,
        width: 20
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    }
        try {
          dataSource={
            xAxis: {
              categories: [
                previousquarter,
                localquarter,
              ]
            },
            series: [
              {
                borderWidth: .5,
                dataLabels: {
                  enabled: true,
                  color: "#000000"
                },
                data: [
                  { x: 0,
                    y: 0,
                    value: prevAssesment,
                    name: "Project Assessment",
                    color: colorCode[prevAssesment] 
                  },{ x: 1,
                    y: 0,
                    value: selAssesment,
                    name: "Project Assessment",
                    color: colorCode[selAssesment] 
                  },
                  { x: 0,
                    y: 1,
                    value: prevRelevance,
                    name: "Project Relevance",
                    color: colorCode[prevRelevance] 
                  },
                  { x: 1,
                    y: 1,
                    value: selRelevance,
                    name: "Project Relevance",
                    color: colorCode[selRelevance] 
                  },
                  { x: 0,
                    y: 2,
                    value: prevAssumptions,
                    name: "Project Assumptions",
                    color: colorCode[prevAssumptions] 
                  },{ x: 1,
                    y: 2,
                    value: selAssumptions,
                    name: "Project Assumptions",
                    color: colorCode[selAssumptions] 
                  },{ x: 0,
                    y: 3,
                    value: prevPlan,
                    name: "Project Progress According to Plan",
                    color: colorCode[prevPlan] 
                  },{ x: 1,
                    y: 3,
                    value: selPlan,
                    name: "Project Progress According to Plan",
                    color: colorCode[selPlan] 
                  },
                ],
              }
            ]
        }
      } catch (error) {
        dataSource={}
      } 
        
    
    // Adding Heat map options to the constant options
    dataSource={
      ...heatmapConstantOptions,
      ...dataSource
    }
    return (
      <HighchartsReact highcharts={Highcharts} options={dataSource} />
    );
}


